<template>
  <el-form
    ref="mainForm"
    :model="form"
    :rules="rules"
    :label-width="this.$widthRatio * 120 + 'px'"
  >
    <el-tabs v-model="activeName">
      <el-tab-pane label="Basic Information" name="studentInformationTab">
        <table
          style="width: 100%; max-width: 1024px; border: solid 3px #000; margin: 0 auto"
        >
          <tr>
            <td style="width: 40%; text-align: center">
              <el-image
                style="width: auto; margin-top: 8px; height: 360px"
                :src="profileUrl"
                :zoom-rate="1.2"
                :preview-src-list="[profileUrl]"
                :initial-index="4"
                fit="cover"
              >
                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
            </td>
            <td style="padding-right: 100px" rowspan="2">
              <el-form-item label="Student No.">
                <el-input readonly v-model="form.studentId"></el-input>
              </el-form-item>
              <el-form-item readonly prop="firstName" label="First Name">
                <el-input v-model="form.firstName"></el-input>
              </el-form-item>
              <el-form-item readonly prop="lastName" label="Last Name">
                <el-input v-model="form.lastName"></el-input>
              </el-form-item>
              <el-form-item prop="email" label="Email">
                <el-input v-model="form.email"></el-input>
              </el-form-item>
              <el-form-item prop="mobileNo" label="Mobile No.">
                <el-input v-model="form.mobileNo"></el-input>
              </el-form-item>
              <div class="buttons">
                <el-button type="primary" @click="saveProfile"
                  >Upload My Profile</el-button
                >
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: center">
              <el-icon
                style="
                  color: orange;
                  width: 48px;
                  height: 48px;
                  cursor: pointer;
                  margin-top: -8px;
                "
              >
                <camera-filled
                  style="width: 48px; height: 48px"
                  @click="editProfilePicture"
                />
              </el-icon>
            </td>
          </tr>
        </table>
      </el-tab-pane>
      <el-tab-pane label="Parent/Guardian" name="parentTab">
        <div v-for="(item, index) in form.parents" :key="index">
          <el-form-item
            :prop="`parents.${index}.firstName`"
            :rules="rules.parentFirstName"
            label="First Name"
          >
            <el-input v-model="item.firstName"></el-input>
          </el-form-item>
          <el-form-item
            :prop="`parents.${index}.lastName`"
            :rules="rules.parentLastName"
            label="Last Name"
          >
            <el-input v-model="item.lastName"></el-input>
          </el-form-item>
          <el-form-item
            :prop="`parents.${index}.email`"
            :rules="rules.parentEmail"
            label="Email"
          >
            <el-input v-model="item.email"></el-input>
          </el-form-item>
          <el-form-item
            :prop="`parents.${index}.relationship`"
            :rules="rules.parentRelationship"
            label="Relationship"
          >
            <el-select v-model="item.relationship" placeholder="Please select...">
              <el-option label="Father" value="Father"></el-option>
              <el-option label="Mother" value="Mother"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :prop="`parents.${index}.address`"
            :rules="rules.parentAddress"
            label="Address"
          >
            <el-input v-model="item.address"></el-input>
          </el-form-item>
          <el-form-item
            :prop="`parents.${index}.suburb`"
            :rules="rules.parentSurburb"
            label="Suburb"
          >
            <el-input v-model="item.suburb"></el-input>
          </el-form-item>
          <el-form-item
            :prop="`parents.${index}.postcode`"
            :rules="rules.parentPostcode"
            label="Postcode"
          >
            <el-input v-model="item.postcode"></el-input>
          </el-form-item>
          <el-form-item label="How did you hear about us?">
            <el-select
              v-model="item.whereToKnow"
              @change="selectChanged(index, $event)"
              placeholder="Please select..."
              style="float: left"
            >
              <el-option
                v-for="optItem in options.whereToKnowOptions"
                :key="optItem.label"
                :label="optItem.label"
                :value="optItem.label"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="item.whereToKnowAddition"
              :type="whereToKnowInputType"
              style="float: left; margin-left: 20px; width: 30%"
            ></el-input>
          </el-form-item>
          <div class="buttons">
            <el-button type="primary" @click="saveProfile"> Upload My Profile </el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Courses of Interest" v-if="false" name="coursesOfInterestTab">
        <el-form-item label="Junior Courses">
          <el-checkbox-group v-model="form.coursesOfInterestArray">
            <el-checkbox label="Year 6(Year 7 Maths Course)"></el-checkbox>
            <el-checkbox label="Year 7(Year 8 Maths Course)"></el-checkbox>
            <el-checkbox label="Year 8(Year 9 Maths Course)"></el-checkbox>
            <el-checkbox label="Year 9(Year 10 Maths Course)"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item prop="coursesOfInterestArray" label="HSC Courses">
          <el-checkbox-group v-model="form.coursesOfInterestArray">
            <el-checkbox label="Year 10 - Advanced Maths course"></el-checkbox>
            <el-checkbox label="Year 11 - Advanced Maths course"></el-checkbox>
            <el-checkbox label="Year 11 - Extension 1 Maths course"></el-checkbox>
            <el-checkbox label="Year 11 - Extension 2 Maths course"></el-checkbox>
            <el-checkbox label="Year 12 - Extension 1 Maths course"></el-checkbox>
            <el-checkbox label="Year 12 - Extension 2 Maths course"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="buttons">
          <el-button type="primary" @click="saveProfile"> Upload My Profile </el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-form>
  <el-dialog
    :title="userProfilePictureEditorDialogTitle"
    v-model="showUserProfilePictureEditorDialog"
    width="50%"
    center
  >
    <user-profile-picture-editor
      :parent="this"
      :isForAdmin="false"
      :userId="query.studentId"
      :handleResult="handleEditProfilePictureResult"
      :close-on-click-modal="false"
    />
  </el-dialog>
</template>
<script>
import { getData, putData } from "../../service/api";
import UserProfilePictureEditor from "../common/UserProfilePictureEditor.vue";

export default {
  components: { UserProfilePictureEditor },
  data() {
    return {
      controllerUrl: "/student",
      options: {
        gradeList: [],
        whereToKnowOptions: [
          {
            label: "Google Search Ads",
          },
          {
            label: "YouTube Ads",
          },
          {
            label: "Recommend from friends",
          },
          {
            label: "From Mr Ye.",
          },
          {
            label: "Other",
          },
        ],
      },
      query: {
        studentId: null,
      },
      activeName: "studentInformationTab",
      whereToKnowInputType: "hidden",
      showUserProfilePictureEditorDialog: false,
      userProfilePictureEditorDialogTitle: "",
      profileUrl: null,
      form: { coursesOfInterestArray: [] },
      rules: {
        firstName: [
          {
            required: true,
            message: "Please input student First Name in Basic Information tab",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please input student Last Name in Basic Information tab",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            pattern: /^[a-zA-Z0-9.!#$%&'^_`{}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: "Please input correct Email in Basic Information tab",
            trigger: "blur",
          },
        ],
        parentEmail: [
          {
            required: false,
            pattern: /^[a-zA-Z0-9.!#$%&'^_`{}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: "Please input correct email in Parent/Guardian tab",
            trigger: "blur",
          },
        ],
        mobileNo: [
          {
            required: true,
            message: "Please input your mobile number in Basic Information tab",
            trigger: "blur",
          },
        ],
        // grade: [
        //   {
        //     required: true,
        //     message: "Please select your current grade",
        //     trigger: "change",
        //   },
        // ],
        // coursesOfInterestArray: [
        //   {
        //     required: true,
        //     type: "array",
        //     message:
        //       "Please select courses of your interest in Courses of Interest tab.",
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;

    let studentId = this.$user.getUsername();
    console.log("StudentProfile.created", studentId);
    if (studentId) {
      this.query.studentId = studentId;
      this.loadProfile();
    }
  },
  methods: {
    loadProfile() {
      console.log("StudentProfile.loadProfile");
      getData(`${this.controllerUrl}/${this.query.studentId}`)
        .then((res) => {
          if (res.result && res.code === "200") {
            console.log("StudentProfile.loadProfile", this, res.result);
            this.form = res.result;
            this.profileUrl = this.$user.buildProfileUrl();
            this.form["coursesOfInterestArray"] = this.form.coursesOfInterest
              ? this.form.coursesOfInterest.split(",")
              : [];
            if (this.form.parents && this.form.parents.length > 0) {
              for (let i = 0; i < this.form.parents.length; i++) {
                this.selectChanged(i, this.form.parents[i].whereToKnow);
              }
            }
          } else {
            this.$message.error(
              "Fetch student data failed, error message: " + res.message
            );
          }
        })
        .catch(() => {});
    },
    saveProfile() {
      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          let coursesOfInterestArray = this.form["coursesOfInterestArray"];
          if (coursesOfInterestArray && coursesOfInterestArray.length > 0) {
            this.form.coursesOfInterest = "".concat(coursesOfInterestArray);
            console.log("this.form.coursesOfInterest", this.form.coursesOfInterest);
          } else {
            this.form.coursesOfInterest = "";
          }
          putData(this.controllerUrl, this.form.studentId, this.form).then((res) => {
            console.log("res", res);
            if (res.result && res.code === "200") {
              this.$message.success("Update successfully");
            } else {
              this.$message.error("Update failed, error message: " + res.message);
            }
          });
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
    selectChanged(index, item) {
      if (item === "Recommend from friends" || item === "Other") {
        this.whereToKnowInputType = "input";
      } else {
        this.whereToKnowInputType = "hidden";
        this.form.parents[index].whereToKnowAddition = "";
      }
    },
    checkEmail(rule, value, callback) {
      if (value) {
        getData(`${this.controllerUrl}/checkEmailExists/${value}`)
          .then((res) => {
            if (res.code === "200" && res.result && res.result !== this.query.studentId) {
              callback(new Error(`The email ${value} already exists.`));
              return;
            }
            callback();
          })
          .catch(() => {});
      }
    },
    editProfilePicture() {
      this.showUserProfilePictureEditorDialog = true;
      this.userProfilePictureEditorDialogTitle = "Profile picture";
    },
    handleEditProfilePictureResult(result) {
      if (result) {
        this.profileUrl = this.$user.refreshProfileUrl();
      }
      this.showUserProfilePictureEditorDialog = false;
      this.userProfilePictureEditorDialogTitle = "";
    },
  },
};
</script>
<style>
.el-image-viewer__close {
  background-color: #ffffff;
}
.el-image-viewer__close .el-icon {
  color: red;
}
.el-image-viewer__mask {
  opacity: 0.7;
}
</style>
<style scoped>
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.buttons {
  text-align: center;
}
</style>
