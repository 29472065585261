<template>
  <div class="notes-container">
    <el-button type="primary" @click="selectPictureFromComputer">
      Select from my computer
    </el-button>
    <div class="avatar-notes">jpg/png, ≤ 500Kb.</div>
    <input
      id="trueSelectFileButton"
      type="file"
      style="display: none"
      accept=".jpg,.jpeg,.png"
      @change="handleUploadChange"
    />
  </div>
  <div class="picture-display">
    <div class="display-container"></div>
  </div>
  <div style="display: none" id="profilePictureEditor" class="editor-container">
    <div
      class="column1"
      :style="
        'position:relative;height:' +
        canvasHeight +
        'px;display:' +
        (inPreview ? 'none' : 'block')
      "
    >
      <canvas id="profileImageCanvas"></canvas>
      <div style="position: absolute; right: 10px; top: 45%; text-align: left">
        <div>
          <img
            src="../../assets/img/mouse-move.png"
            style="height: 32px; vertical-align: middle"
          />
          Mouse left button
        </div>
        <div style="margin-top: 5px">
          <img
            src="../../assets/img/zoom.png"
            style="height: 32px; vertical-align: middle"
          />
          Mouse wheel
        </div>
      </div>
    </div>
    <div
      class="column2"
      :style="'height:' + canvasHeight + 'px;display:' + (inPreview ? 'block' : 'none')"
    >
      <img id="profilePreviewImage" />
    </div>
    <div class="buttons-panel">
      <el-button @click="handleCancel"> Cancel </el-button>
      <el-button type="success" @click="previewImage" v-if="!inPreview">
        Preview
      </el-button>
      <el-button @click="backToEditing" v-if="inPreview"> Back to Editing </el-button>
      <el-button type="primary" @click="saveProfileImage">
        Save as profile picture
      </el-button>
    </div>
  </div>
</template>

<script>
import { postData } from "../../service/api";

export default {
  props: {
    parent: Object,
    isForAdmin: Boolean,
    userId: String,
    handleResult: Function,
  },
  data() {
    return {
      controllerUrl: "/user",
      originalImage: null,
      originalImageUrl: "file:///C:/Users/Li/Pictures/Roblox/roblox-ring-pink.png",

      scaleFactor: 0.025,
      imageScaleValue: 1,
      imageLastScaleValue: 1,
      imageMaxScaleValue: 5,
      imageMinScaleValue: 0.25,
      fileList: [],
      maxFileSize: 512000,
      allowedExtension: ["image/jpeg", "image/jpg", "image/png"],
      crossLength: 40,

      isMouseDown: false,
      isMouseOver: false,
      canvasHeight: 600,
      canvasWidth: 0,
      startPointOfImage: { x: 0, y: 0 },
      startPointOfFrame: { x: 0, y: 0 },
      lastClientPointOfMouse: { x: 0, y: 0 },
      zoomPoint: { x: 0, y: 0 },

      inPreview: false,

      arcHeightOfFrame: 0,
      bodyWidthOfFrame: 0,
      bodyHeightOfFrame: 0,

      imageWidth: 0,
      imageHeight: 0,

      ratioOfBodyWidthWithBodyHeight: 0.667,
      ratioOfArcHeightWithBodyHeight: 0.278,
      ratioOfCanvasHeightWithWidth: 0.75,
      minHeightOfFrame: 0,
      frameDefinition: [],
      alphaValueOfOutArea: 0.1,
    };
  },
  created() {},
  methods: {
    selectPictureFromComputer() {
      let selectFileButton = document.getElementById("trueSelectFileButton");
      selectFileButton.click();
    },
    handleUploadChange: function (evt) {
      let selectFileButton = document.getElementById("trueSelectFileButton");
      let file = selectFileButton.files[0];
      console.log("handleUploadChange", evt, file);

      if (this.allowedExtension.indexOf(file.type) < 0) {
        this.$message.warning(
          `The file "${file.name}" with extension is unacceptable, please re select.`
        );
        return;
      }
      if (file.size > this.maxFileSize) {
        this.$message.warning(
          `The file "${file.name}" is exceeding 500Kb, please re select.`
        );
        return;
      }

      this.inPreview = false;
      document.getElementById("profilePictureEditor").style.display = "block";
      this.fileList.push(file);
      this.originalImageUrl = URL.createObjectURL(file);
      this.initAndLoadImage();
    },
    initAndLoadImage: function () {
      var canvas = document.getElementById("profileImageCanvas");
      var ctx = canvas.getContext("2d");

      this.minHeightOfFrame = this.canvasHeight * 0.8;
      this.canvasWidth = this.canvasHeight / this.ratioOfCanvasHeightWithWidth;
      canvas.width = this.canvasWidth;
      canvas.height = this.canvasHeight;

      this.originalImage = new Image();
      this.originalImage.src = this.originalImageUrl;

      var _this = this;
      this.originalImage.onload = function () {
        _this.imageScaleValue = 1;
        _this.calculateImageSizeAndPosition(canvas, this, _this.imageScaleValue, null);
        _this.calculateFrameSize(this, _this.imageScaleValue);
        _this.drawImage(canvas, ctx, this);
        _this.imageLastScaleValue = _this.imageScaleValue;

        canvas.addEventListener("mousedown", function (evt) {
          _this.handleMouseDown(canvas, ctx, evt);
        });
        canvas.addEventListener("mouseover", function (evt) {
          _this.handleMouseOver(canvas, ctx, evt);
        });
        canvas.addEventListener("mouseup", function (evt) {
          _this.handleMouseUp(canvas, ctx, evt);
        });
        canvas.addEventListener("mousemove", function (evt) {
          _this.handleMouseMove(canvas, ctx, evt);
        });
        canvas.addEventListener("wheel", function (evt) {
          _this.handleMouseWheel(canvas, ctx, evt);
        });
      };
    },
    calculateImageSizeAndPosition: function (canvas, image, scale, evt) {
      this.imageWidth = image.width;
      this.imageHeight = image.height;

      if (scale !== 1) {
        this.imageWidth = this.imageWidth * scale;
        this.imageHeight = this.imageHeight * scale;
      }

      let originalX = this.canvasWidth - image.width;
      let originalY = this.canvasWidth - image.height;
      if (evt) {
        let disX = (evt.offsetX - this.startPointOfImage.x) / this.imageLastScaleValue;
        let disY = (evt.offsetY - this.startPointOfImage.y) / this.imageLastScaleValue;

        this.startPointOfImage.x = evt.offsetX - disX * scale;
        this.startPointOfImage.y = evt.offsetY - disY * scale;
      } else {
        this.startPointOfImage = {
          x: parseInt(originalX / 2),
          y: parseInt(originalY / 2),
        };
      }
    },
    calculateFrameSize: function () {
      // if (this.imageHeight > this.canvasHeight) {
      //     this.bodyHeightOfFrame = parseInt(this.canvasHeight / (1 + 2 * this.ratioOfArcHeightWithBodyHeight));
      // } else if (this.imageHeight < this.minHeightOfFrame) {
      //     this.bodyHeightOfFrame = parseInt(this.minHeightOfFrame / (1 + 2 * this.ratioOfArcHeightWithBodyHeight));
      // } else {
      //     this.bodyHeightOfFrame = parseInt(this.imageHeight / (1 + 2 * this.ratioOfArcHeightWithBodyHeight));
      // }
      this.bodyHeightOfFrame = parseInt(
        this.canvasHeight / (1 + 2 * this.ratioOfArcHeightWithBodyHeight)
      );
      this.bodyWidthOfFrame = parseInt(
        this.bodyHeightOfFrame * this.ratioOfBodyWidthWithBodyHeight
      );
      this.arcHeightOfFrame = parseInt(
        this.bodyHeightOfFrame * this.ratioOfArcHeightWithBodyHeight
      );

      this.startPointOfFrame = {
        x: parseInt((this.canvasWidth - this.bodyWidthOfFrame) / 2),
        y: parseInt(
          (this.canvasHeight - 2 * this.arcHeightOfFrame - this.bodyHeightOfFrame) / 2
        ),
      };

      this.frameDefinition = [];
      this.frameDefinition.push({
        name: "moveTo",
        points: [
          {
            x: this.startPointOfFrame.x,
            y: this.startPointOfFrame.y + this.arcHeightOfFrame,
          },
        ],
      });
      this.frameDefinition.push({
        name: "bezierCurveTo",
        points: [
          { x: this.startPointOfFrame.x, y: this.startPointOfFrame.y },
          {
            x: this.startPointOfFrame.x + this.bodyWidthOfFrame,
            y: this.startPointOfFrame.y,
          },
          {
            x: this.startPointOfFrame.x + this.bodyWidthOfFrame,
            y: this.startPointOfFrame.y + this.arcHeightOfFrame,
          },
        ],
      });
      this.frameDefinition.push({
        name: "lineTo",
        points: [
          {
            x: this.startPointOfFrame.x + this.bodyWidthOfFrame,
            y: this.startPointOfFrame.y + this.arcHeightOfFrame + this.bodyHeightOfFrame,
          },
        ],
      });
      this.frameDefinition.push({
        name: "bezierCurveTo",
        points: [
          {
            x: this.startPointOfFrame.x + this.bodyWidthOfFrame,
            y:
              this.startPointOfFrame.y +
              2 * this.arcHeightOfFrame +
              this.bodyHeightOfFrame,
          },
          {
            x: this.startPointOfFrame.x,
            y:
              this.startPointOfFrame.y +
              2 * this.arcHeightOfFrame +
              this.bodyHeightOfFrame,
          },
          {
            x: this.startPointOfFrame.x,
            y: this.startPointOfFrame.y + this.arcHeightOfFrame + this.bodyHeightOfFrame,
          },
        ],
      });
      this.frameDefinition.push({
        name: "lineTo",
        points: [
          {
            x: this.startPointOfFrame.x,
            y: this.startPointOfFrame.y + this.arcHeightOfFrame,
          },
        ],
      });
    },
    drawImage: function (
      canvas,
      ctx,
      image,
      drawCross = true,
      drawBG = true,
      returnImageData = false
    ) {
      ctx.save();
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();

      if (drawBG) {
        // draw blur background
        ctx.globalAlpha = this.alphaValueOfOutArea;
        ctx.drawImage(
          image,
          this.startPointOfImage.x,
          this.startPointOfImage.y,
          this.imageWidth,
          this.imageHeight
        );
        ctx.globalAlpha = 0.5;
        ctx.fillStyle = "#333333";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.globalAlpha = 1;
      }

      for (let i = 0; i < this.frameDefinition.length; i++) {
        let funcName = this.frameDefinition[i].name;
        let args = [];
        for (let j = 0; j < this.frameDefinition[i].points.length; j++) {
          args.push(this.frameDefinition[i].points[j].x);
          args.push(this.frameDefinition[i].points[j].y);
        }
        ctx[funcName].apply(ctx, args);
      }

      ctx.closePath();
      // ctx.lineWidth = 4;
      // ctx.strokeStyle = "#CCCCCC";
      // ctx.setLineDash([20, 10]);
      // ctx.stroke();
      ctx.clip();
      // draw image in the frame
      ctx.drawImage(
        image,
        this.startPointOfImage.x,
        this.startPointOfImage.y,
        this.imageWidth,
        this.imageHeight
      );

      ctx.restore();

      console.log(drawCross);
      if (drawCross) {
        let offsetX = (canvas.width - this.crossLength) / 2;
        let offsetY = (canvas.height - this.crossLength) / 2;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(offsetX, offsetY + this.crossLength / 2);
        ctx.lineTo(offsetX + this.crossLength, offsetY + this.crossLength / 2);
        ctx.moveTo(offsetX + this.crossLength / 2, offsetY);
        ctx.lineTo(offsetX + this.crossLength / 2, offsetY + this.crossLength);
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#FFFFFF";
        ctx.stroke();
        ctx.restore();
      }

      ctx.save();
      ctx.beginPath();
      for (let i = 0; i < this.frameDefinition.length; i++) {
        let funcName = this.frameDefinition[i].name;
        let args = [];
        for (let j = 0; j < this.frameDefinition[i].points.length; j++) {
          args.push(this.frameDefinition[i].points[j].x);
          args.push(this.frameDefinition[i].points[j].y);
        }
        ctx[funcName].apply(ctx, args);
      }

      ctx.closePath();
      ctx.clip();
      ctx.restore();

      if (returnImageData) {
        return ctx.getImageData(0, 0, this.canvasWidth, this.canvasHeight);
      }
    },
    handleMouseWheel: function (canvas, ctx, evt) {
      if (this.isMouseOver) {
        var delta = evt.wheelDelta ? evt.wheelDelta : -evt.deltaY;
        delta = Math.max(-1, Math.min(1, delta));
        // apply zoom
        this.imageScaleValue += delta * this.scaleFactor * this.imageScaleValue;
        this.imageScaleValue = Math.max(
          this.imageMinScaleValue,
          Math.min(this.imageMaxScaleValue, this.imageScaleValue)
        );

        console.log("handleMouseWheel", this.imageScaleValue, evt, delta);
        // draw image
        this.calculateImageSizeAndPosition(
          canvas,
          this.originalImage,
          this.imageScaleValue,
          evt
        );

        this.drawImage(canvas, ctx, this.originalImage);
        this.imageLastScaleValue = this.imageScaleValue;

        // tell the browser we're handling this event
        evt.preventDefault();
        evt.stopPropagation();
      }
    },
    handleMouseOver: function (canvas, ctx, evt) {
      // tell the browser we're handling this event
      evt.preventDefault();
      evt.stopPropagation();
    },
    handleMouseUp: function () {
      this.isMouseDown = false;
    },
    handleMouseMove: function (canvas, ctx, evt) {
      // tell the browser we're handling this event
      evt.preventDefault();
      evt.stopPropagation();
      if (this.isMouseDown && this.isMouseOver) {
        console.log(
          "The mouse is moving inside this shape",
          evt,
          this.lastClientPointOfMouse
        );
        this.startPointOfImage = {
          x: this.startPointOfImage.x + evt.clientX - this.lastClientPointOfMouse.x,
          y: this.startPointOfImage.y + evt.clientY - this.lastClientPointOfMouse.y,
        };
        this.drawImage(canvas, ctx, this.originalImage);
        this.lastClientPointOfMouse = { x: evt.clientX, y: evt.clientY };
      } else {
        this.lastClientPointOfMouse = { x: evt.clientX, y: evt.clientY };

        let isHover = false;
        let rect = canvas.getBoundingClientRect();
        let offsetX = rect.left;
        let offsetY = rect.top;

        let mouseX = parseInt(evt.clientX - offsetX);
        let mouseY = parseInt(evt.clientY - offsetY);
        if (ctx.isPointInPath(mouseX, mouseY)) {
          isHover = true;
          console.log("The mouse is inside this shape");
        }
        if (isHover) {
          this.isMouseOver = true;
          canvas.style.cursor = "pointer";
        } else {
          this.isMouseOver = false;
          canvas.style.cursor = "";
        }
      }
    },
    handleMouseDown: function (canvas, ctx, evt) {
      // tell the browser we're handling this event
      evt.preventDefault();
      evt.stopPropagation();
      this.isMouseDown = true;
    },
    cropImage: function () {
      var canvas1 = document.createElement("canvas");
      canvas1.width = this.canvasWidth;
      canvas1.height = this.canvasHeight;
      var ctx1 = canvas1.getContext("2d");
      ctx1.fillStyle = "transparent";
      var imageData = this.drawImage(
        canvas1,
        ctx1,
        this.originalImage,
        false,
        false,
        true
      );

      var canvas2 = document.createElement("canvas");
      canvas2.width = this.canvasWidth;
      canvas2.height = this.canvasHeight;
      var ctx2 = canvas2.getContext("2d", { willReadFrequently: true });
      ctx2.rect(0, 0, canvas2.width, canvas2.height);
      ctx2.fillStyle = "transparent";
      ctx2.fill();
      ctx2.putImageData(imageData, 0, 0);
      ctx2.trim();

      var dstImg = document.getElementById("profilePreviewImage");
      dstImg.src = canvas2.toDataURL("image/png");
    },
    saveProfileImage: function () {
      this.cropImage();
      var dstImg = document.getElementById("profilePreviewImage");
      postData("/file/UploadProfile", dstImg.src).then((res) => {
        console.log("res", res);
        if (res.result && res.code === "200") {
          this.$message.success("Your profile has been updated successfully.");
          if (this.handleResult) {
            this.handleResult.call(this.parent, true);
          }
        } else {
          this.$message.error("Update failed, error message: " + res.message);
        }
      });
    },
    handleCancel: function () {
      if (this.handleResult) {
        this.handleResult.call(this.parent, false);
      }
      this.imageScaleValue = 1;
      this.fileList = [];
      document.getElementById("profilePictureEditor").style.display = "none";
    },
    previewImage() {
      this.inPreview = true;
      this.cropImage();
    },
    backToEditing() {
      this.inPreview = false;
    },
  },
};
</script>
<style scoped>
.picture-display,
.editor-container,
.buttons-panel {
  margin-top: 20px;
}
.picture-display {
  display: none;
}
.editor-container {
  margin: 0 10px;
  width: 100%;
}
.editor-container .column1,
.editor-container .column2 {
  width: 100%;
  text-align: center;
}
.editor-container .column2 img {
  padding-top: 26px;
}
.notes-container {
  margin-bottom: 20px;
  text-align: center;
}
.notes-container .notes,
.notes-container .avatar-notes {
  margin-right: 6px;
  font-size: 1.5rem;
}
.notes-container .avatar-notes {
  margin-right: 0;
  margin-left: 6px;
  font-size: 1.34rem;
  color: gray;
}
.buttons-panel {
  text-align: center;
}
</style>
